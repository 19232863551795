const sendRegisterTrackToGoogleAdsAndAnalytics = () => {
  // Google tag manager
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-769894422/7wKWCJ_UkNwBEJbQju8C',
    });
    window.gtag('event', 'Registro', {
      event_category: 'Registro',
      event_label: 'Registro Usuario',
      send_to: process.env.NEXT_PUBLIC_GANALYTICS_ID,
    });
  }
};

export default sendRegisterTrackToGoogleAdsAndAnalytics;
